import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateInternetRfpProjectGeneralInformation from './CreateInternetRfpProjectGeneralInformation__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  redux: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateInternetRfpProjectGeneralInformationContainer = connect(
  null,
  mapDispatchToProps,
)(CreateInternetRfpProjectGeneralInformation);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateInternetRfpProjectGeneralInformationContainer jsProps={props} railsContext={context} />
  </ReduxProvider>
);
